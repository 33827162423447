// Styled list
dl.floatList {
  @include pie-clearfix;

  dt,
  dd {
    display: block;
    vertical-align: top;
    float: left;
  }

  dt {
    padding-right: 0.5rem;
    width: auto;
    clear: left;

    @include breakpoint(large) {
      width: 50%;
    }
  }

  dd {
    width: auto;
  }
  
  &.contacts{
    dt{
      @include breakpoint(large){
        width: 35%;
      }
    }
  }
}

// SOME HELPFUL CLASSES
//////////////////////////////

.highlight {
  color: $secondary;
}
.frame {
  width: 100%;
  height: 800px;
}
.center {
  text-align: center;
}

.btn {
  background: $alert;
  border: none;
  color: $light;
  cursor: pointer;
  display: table;
  font-size: 1rem;
  line-height: 1rem;
  padding: 1.1rem;
  text-align: center;
  text-decoration: none;
  transition: 300ms all ease-in-out;
  margin-bottom: 1rem;

  &:hover,
  &:active,
  &:focus {
    background: $dark;
    color: $light;
  }

  *[class^="icon"] {
    margin-left: 0.5rem;
    vertical-align: middle;
  }
}

.notification {
  padding: 1em;
  background: $alert;
  color: $light;
  font-size: 1.2em;
}
hr {
  margin: 2.5rem 0;
}
// PAGE WRAP
//////////////////////////////

.page-wrap {
  padding-top: rem($menu-bar);

  @include breakpoint(medium) {
    padding-top: 0;
  }
}

// CONTENT CONTAINER
//////////////////////////////

.content-container {
  background: $light;
  box-shadow: none !important;
  @include breakpoint(huge) {
    max-width: 90%;
    width: 100%;
    margin: 2em auto;
  }
}

// HEADER
//////////////////////////////

.header {
  background: $light;
  position: relative;
  padding: rem(10px) rem($base-gap);
  width: 100%;

  .branding {
    text-align: center;
    width: 100%;
    display: inline-block;

    img {
      width: auto;
    }
  }
}

.header-secondary {
  background: url("/images/bg-header.jpg") no-repeat center/cover;
  position: relative;
  width: 100%;
  text-align: center;
  overflow: hidden;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .claim-container {
    padding: rem(60px) 0;

    @include breakpoint(medium) {
      padding: rem(150px) 0;
    }

    @include breakpoint(huge) {
      padding: rem(206px) 0 rem(246px);
    }

    body:not(.index) & {
      display: none;

      @include breakpoint(medium) {
        display: block;
        padding: rem(70px) 0;
      }
    }

    .claim {
      color: $light;
      font-weight: 500;
      font-size: rem(26px);
      line-height: rem(34px);
      margin-bottom: 1em;

      @include breakpoint(giant) {
        font-size: rem(40px);
        line-height: rem(60px);
      }

      body:not(.index) & {
        margin-bottom: 0;
      }

      span {
        display: block;
        font-size: rem(18px);
        line-height: rem(26px);

        @include breakpoint(giant) {
          font-size: rem(26px);
          line-height: rem(36px);
        }
      }
    }

    ul {
      li {
        display: inline-block;
        font-weight: 500;
        margin-right: 1em;
      }
    }
  }

  .video-container {
    display: none;

    @include breakpoint(large) {
      position: absolute;
      left: 0;
      top: -18em;
      z-index: 0;
      min-height: 100vh;
      min-width: 100%;
      display: block;
      overflow: hidden;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        display: block;
        z-index: 200;
      }
    }

    @include breakpoint(giant) {
      top: -5em;
    }

    body:not(.index) & {
      @include breakpoint(giant) {
        display: none;
      }
    }

    video {
      min-width: 100%;
      min-height: 100vh;
      margin-top: 0px;
      overflow: hidden;
      position: relative;
    }
  }
}

.header-tertiary {
  font-weight: 300;
  overflow-x: hidden;
  background: $dark;
  span {
    &:before {
      content: "";
      width: rem(31px);
      height: rem(31px);
      display: inline-block;
      position: absolute;
      left: 0;
    }

    font-weight: 500;
    display: block;
    font-size: rem(21px);
    margin-bottom: 1em;

    @include breakpoint(medium) {
      font-size: rem(25px);
    }
  }

  .dark {
    background: $dark;
    color: $light;
    padding: 2.5em 1em;
    position: relative;
    font-size: rem(15px);

    @include breakpoint(large) {
      padding-right: 3em;
    }

    &:before {
      content: "";
      background: $dark;
      height: 100%;
      width: 100%;
      position: absolute;
      left: -98%;
      top: 0;
    }

    img {
      display: none;

      @include breakpoint(huge) {
        display: block;
      }
    }
  }

  .secondary {
    background: $secondary;
    color: $light;
    padding: 2.5em 1em;
    position: relative;
    font-size: rem(15px);

    a {
      &:hover {
        color: $light;
      }
    }

    .row {
      height: 100%;
    }

    @include breakpoint(large) {
      padding-left: 3em;
    }

    &:after {
      content: "";
      background: $secondary;
      height: 100%;
      width: 100%;
      position: absolute;
      left: 100%;
      top: 0;
    }

    img {
      width: auto;
      margin-bottom: 1.5em;

      @include breakpoint(tiny) {
        margin-bottom: 0;
      }
    }

    .social {
      position: relative;
      float: left;
      display: inline-block;
      text-indent: -999999px;
      height: rem(57px);

      &:before {
        content: "";
        width: rem(51px);
        height: rem(51px);
        display: inline-block;
        position: absolute;
        top: rem(6px);
        left: 0;
        transition: all linear 200ms;
      }

      &.instagram:before {
        background: url(/images/instagram.png) rgba(255, 255, 255, 0.15) 10px
          10px no-repeat;
      }
      &.twitter:before {
        background: url(/images/socials.png) rgba(255, 255, 255, 0.15) -97px 10px
          no-repeat;
        left: rem(62px);
      }
      &.linkedin:before {
        background: url(/images/socials.png) rgba(255, 255, 255, 0.15) 8px 10px
          no-repeat;
        left: rem(124px);
      }

      &:hover {
        &.instagram:before {
          background: url(/images/instagram.png) rgba(255, 255, 255, 0.75) 10px
            10px no-repeat;
        }
        &.twitter:before {
          background: url(/images/socials.png) rgba(255, 255, 255, 0.75) -97px 10px
            no-repeat;
          left: rem(62px);
        }
        &.linkedin:before {
          background: url(/images/socials.png) rgba(255, 255, 255, 0.75) 8px
            10px no-repeat;
          left: rem(124px);
        }
      }
    }
  }
}

// MAIN CONTENT
//////////////////////////////

main {
  display: block;
  padding: 3em 0;

  ul {
    @extend .styled-list;
  }
}

body.lawyer-referral img {
  width: 300px;

  max-height: 360px;
}

.upcoming-events {
  background: $medium;
  width: 100%;
  height: auto;
  padding: 3em 0;
  margin: 3em 0;
  display: inline-block;
}
.framemargin {
  margin-bottom: 4rem;
}
body.news .news_head_col {
  margin-bottom: 3rem;
  .news_head {
    margin: 0;
  }
}
.entry_img {
  max-height: 750px;
  max-width: 100%;
  width: auto;
}

body.events .row .framemargin {
  margin-bottom: 4rem;
}
.news-box {
  background: url(/images/bg-news.jpg) no-repeat center/cover;
  padding: 3em 0;
  margin: 3em 0;
  display: inline-block;
  width: 100%;
  .newscol {
    display: flex;
    flex-direction: row;
    .ewcms_news_feed {
      display: flex;
      flex-direction: column;

      @include breakpoint(medium) {
        flex-direction: initial;
      }
      div {
        padding: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      .ewcms_news_feed_h1 {
        color: $light;
        margin: 0;
        font-size: 1rem;
      }
      span {
        margin-bottom: 1em;
        color: #e0e0e0;
        font-size: 0.6rem;
      }
      p {
        color: $light;
      }
      .ewcms_news_link {
        background: $alert;
        border: none;
        color: $light;
        cursor: pointer;
        display: flex;
        max-width: 130px;
        font-size: 1rem;
        line-height: 1rem;
        padding: 1.1rem;
        text-align: center;
        text-decoration: none;
        transition: 300ms all ease-in-out;
        margin-bottom: 1rem;

        &:hover,
        &:active,
        &:focus {
          background: $dark;
          color: $light;
        }

        *[class^="icon"] {
          margin-left: 0.5rem;
          vertical-align: middle;
        }
      }
    }
  }
}
.popUpContainer {
  body:not(.cmsBackend) & {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    transition: opacity 0.3s, visibility 0.3s;
    visibility: hidden;
    z-index: 1000000;

    .popUpContent {
      background: $light;
      max-height: auto;
      max-width: calc(100% - #{rem(110px)}); // for the close label
      overflow: hidden;
      overflow-y: auto;

      @include breakpoint(large) {
        max-width: 60%;
      }
      @include breakpoint(giant) {
        max-width: 45%;
      }
    }
  }

  label {
    display: none;

    body:not(.cmsBackend) & {
      background: rgba(black, 0.8);
      display: flex;
      height: 100%;
      justify-content: flex-end;
      padding: 1rem;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: -1;

      i {
        cursor: pointer;
        display: block;
        height: rem(35px);
        width: rem(35px);
        position: relative;
        transition: background 0.3s;

        &:hover {
          background: rgba(black, 0.8);

          &:before,
          &:after {
            background: white;
          }
        }

        &:before,
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: rem(30px);
          height: rem(2px);
          background: #ddd;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }
}

input[id^="popUpToggled"] {
  display: none;

  &:checked {
    + {
      .popUpContainer {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }
  }
}

.teaser-flex {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 3em;
  justify-content: space-around;
  padding: 0 1rem;
  .item {
    width: 100%;
    @include breakpoint(large) {
      width: 25%;
    }
  }
  .box {
    border: 1px solid $border;
    text-align: center;
    padding: 2em 0;
    margin-bottom: 1em;

    @include breakpoint(large) {
      margin-bottom: 0;
    }

    a {
      color: $alert;
      font-weight: 500;
      transition: 300ms all;

      &:hover {
        color: $secondary;
      }

      &:after {
        content: "\f0da";
        font-family: FontAwesome;
        margin-left: 0.3rem;
      }

      &:before {
        display: inline-block;
        width: 100%;
        height: 6em;
      }

      &.member:before {
        content: url("/images/member.png");
      }

      &.sponsor:before {
        content: url("/images/sponsor.png");
      }
      &.event:before {
        content: url("/images/event.png");
      }
      &.benefits:before {
        content: url("/images/benefits.png");
      }
      &.news:before {
        content: url("/images/newspaper.png");
      }
    }
  }
}

// FOOTER
//////////////////////////////
.google-maps {
  @extend .video-container;

  @include breakpoint(medium) {
    height: rem(400px);
  }
}

.footer {
  padding: 0 0 3em 0;
  display: block;
  font-weight: 300;

  @include breakpoint(medium) {
    padding: 5em 0;
  }
  @include breakpoint(giant) {
    padding: 3em 0;
  }
  .sponsor-container {
    font-weight: 300;
    overflow-x: hidden;
    background: $dark;
    margin-bottom: 2rem;
    span {
      &:before {
        content: "";
        width: rem(31px);
        height: rem(31px);
        display: inline-block;
        position: absolute;
        left: 0;
      }

      font-weight: 500;
      display: block;
      font-size: rem(18px);
      margin-bottom: 1em;

      // @include breakpoint(medium) {
      //   font-size: rem(25px);
      // }
    }
    .sponsors {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .sponsor {
        display: flex;
        flex-wrap: wrap;
        background: $secondary;
        justify-content: space-around;
        min-width: rem(400px);
        padding: 1.5rem;
        margin: 1rem;
      }
    }

    .secondary {
      color: $light;
      padding: 2.5em 1em;
      position: relative;
      font-size: rem(15px);

      .sponsor-image {
        width: 158px;
        @media (min-width: 485px) {
          margin-right: 1.5rem;
        }
      }
      a {
        &:hover {
          color: $light;
        }
      }
      .social-container {
        display: flex;
        justify-content: space-evenly;
      }
      .social {
        position: relative;
        float: left;
        display: inline-block;
        height: rem(57px);

        & > a {
          line-height: 0;
          font-size: 0;
          color: transparent;
        }
        & > a:before {
          content: "";
          width: rem(51px);
          height: rem(51px);
          display: inline-block;
          transition: all linear 200ms;
        }

        &.instagram > a:before {
          background: url(/images/instagram.png) rgba(255, 255, 255, 0.15) 10px
            10px no-repeat;
        }
        &.twitter > a:before {
          background: url(/images/socials.png) rgba(255, 255, 255, 0.15) -97px 10px
            no-repeat;
          left: rem(62px);
        }
        &.linkedin > a:before {
          background: url(/images/socials.png) rgba(255, 255, 255, 0.15) 8px
            10px no-repeat;
          left: rem(124px);
        }

        &:hover {
          &.instagram > a:before {
            background: url(/images/instagram.png) rgba(255, 255, 255, 0.75)
              10px 10px no-repeat;
          }
          &.twitter > a:before {
            background: url(/images/socials.png) rgba(255, 255, 255, 0.75) -97px
              10px no-repeat;
            left: rem(62px);
          }
          &.linkedin > a:before {
            background: url(/images/socials.png) rgba(255, 255, 255, 0.75) 8px
              10px no-repeat;
            left: rem(124px);
          }
        }
      }
    }
  }
  // .social {
  //   position: relative;
  //   float: left;
  //   display: inline-block;
  //   text-indent: -999999px;
  //   height: rem(57px);

  //   &:before {
  //     content: "";
  //     width: rem(51px);
  //     height: rem(51px);
  //     display: inline-block;
  //     position: absolute;
  //     top: rem(6px);
  //     left: 0;
  //     transition: all linear 200ms;
  //   }

  //   &.instagram:before {
  //     background: url(/images/instagram.png) rgba(255, 255, 255, 0.15) 10px 10px
  //       no-repeat;
  //   }
  //   &.twitter:before {
  //     background: url(/images/socials.png) rgba(255, 255, 255, 0.15) -97px 10px no-repeat;
  //     left: rem(62px);
  //   }
  //   &.linkedin:before {
  //     background: url(/images/socials.png) rgba(255, 255, 255, 0.15) 8px 10px
  //       no-repeat;
  //     left: rem(124px);
  //   }

  //   &:hover {
  //     &.instagram:before {
  //       background: url(/images/instagram.png) rgba(255, 255, 255, 0.75) 10px
  //         10px no-repeat;
  //     }
  //     &.twitter:before {
  //       background: url(/images/socials.png) rgba(255, 255, 255, 0.75) -97px 10px
  //         no-repeat;
  //       left: rem(62px);
  //     }
  //     &.linkedin:before {
  //       background: url(/images/socials.png) rgba(255, 255, 255, 0.75) 8px 10px
  //         no-repeat;
  //       left: rem(124px);
  //     }
  //   }
  // }
  /*Social begins*/

  ul.social {
    @include breakpoint(huge) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    li {
      display: inline-block;
      padding: 0 0.5em;

      &.twitter {
        padding-right: 0;
      }

      a {
        width: 31px;
        height: 31px;
        text-indent: -99999px;
        display: inline-block;
        margin-bottom: 0.5rem;
      }

      &.linkedin {
        a {
          background: url(/images/socials.png) 0 0 no-repeat;
        }
      }
      &.facebook {
        a {
          background: url(/images/socials.png) -55px 0 no-repeat;
        }
      }
      &.twitter {
        a {
          background: url(/images/socials.png) -107px 0 no-repeat;
        }
      }
    }
  }

  /*Social ends*/
}

div.entries {
  text-align: center;
  color: $alert;
  .ct{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    h5 {
      margin-bottom: 16px;
  
      div.entries:last-child & {
        background-color: #333333;
      }
    }
    
    .img-holder{
      height: rem(420px);
      @include breakpoint(large){
        height: rem(360px);
      }
      margin-bottom: rem(75px);
    
      a{
        display: block;
        height: 100%;
        &:hover, &:focus{
          opacity: .8;
        }
      
      }
      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        
      }
    }
    .ewcms_edit_link img {
      display: none;
    }
  }
}

.sponsor-heading-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 4rem 0 1rem;
}
.swiper-button-container {
  position: relative;
  @media screen and (min-width: 37.5rem) {
    margin: 0 auto;
    width: 92%;
    padding: 0 2rem;
  }
  @media screen and (min-width: 43.75rem) {
    width: 95%;
  }
  .swiper-container {
    margin-top: 2em; /* minor cosmetic */
    height: 100%;
  }

  .swiper-wrapper {
    /* now pagination is below slides */
    margin-bottom: 3em;
    height: 100%;

    /* just enough width so slides handing off screen are partially visible */
    width: 73.8%;
  }

  @media (min-width: 37.5em) {
    .swiper-wrapper {
      width: 100%;
    }
  }

  /* doubleing up specificity to win over on transform */
  .swiper-slide.swiper-slide {
    /* a single column layout */
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 37.5em) {
    .swiper-slide.swiper-slide {
      /* going for a 2 column layout here */
      width: 50%;

      /* centeredSlides set to true */
      /* reposition so first slide appears on left */
      transform: translateX(-50%);
    }
  }

  @media (min-width: 43.75em) {
    .swiper-slide.swiper-slide {
      /* upping it to a 3 column layout */
      width: 33.33333333333%;

      /* centeredSlides set to true */
      /* reposition so first slide appears on left */
      transform: translateX(-100%);
    }
  }

  .swiper-pagination {
    /* show on small viewports */
    display: block;
  }

  @media (min-width: 37.5em) {
    .swiper-pagination {
      /* hide on larger viewports per stakeholder recommendation */
      display: none;
    }
  }

  /* now move prev button more to the left */
  .swiper-button-prev {
    /* but first hide on small viewports */
    display: none;

    left: 0;
    top: 50%;
    transform: translateY(-50%);

    margin-top: 0;

    /* subtract .swiper-wrapper bottom-margin to vertically center arrow */
  }

  @media (min-width: 37.5em) {
    .swiper-button-prev {
      /* show on larger viewports */
      display: block;
    }
  }

  .swiper-button-next {
    /* hide on small viewports */
    display: none;

    right: 0;
    top: 50%;
    transform: translateY(-50%);

    margin-top: 0;

    /* subtract .swiper-wrapper bottom-margin to vertically center arrow */
  }

  @media (min-width: 37.5em) {
    .swiper-button-next {
      /* show on larger viewports */
      display: block;
    }
  }
}
.swiper-slide {
  .image-container {
    height: 100%;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    img:not(.ewcms_edit_img) {
      width: 100%;
      height: auto;
      &.ab-logo {
        max-width: 200px;
      }
    }
  }
}
