// Main Navigation
//////////////////////////////

.navi-main {
	display: inline-block;
	width: 100%;
	padding-bottom: 100px;	

	@include breakpoint(medium) {		
		display: flex;
		justify-content: space-around;		
		padding: 1.5em 0;

	}

	@include breakpoint(large) {
		max-width: 60%;
		margin-left: 20%;
	}

	@include breakpoint(huge) {
		max-width: 50%;
		margin-left: 25%;
	}

	li {		
		line-height: 4em;		
		color: $light;
		font-weight: 500;
		padding: 0 20px;
		&:not(:last-child){
			border-bottom: 1px dashed $light;
		}

		@include breakpoint(medium) {
			display: inline-block;
			border-bottom: none;
			color: $primary;
			padding: 0;
			line-height:2em;

		}

		&.benefits {
			border-bottom: none;
		}

		&:hover > ul {
			display: block;
		}

		&.active {
			color: $alert;
			cursor: pointer;
		}


		span {
			&:after {
				font-family:FontAwesome;
				content: "\f0d7";
				margin-left: .3rem;
			}
		}

		a {
			color: $light;
			text-decoration: none;
			transition: 300ms all;
			display: block;
			@include breakpoint(medium) {
				color: $primary;
				display: inline-block;
			}

			&:hover,&.active {
				color: $alert;
			}


			&.login {
			@include breakpoint(medium) {	
				border: 1px solid $border;
				padding:0 .6rem;
			}

			&:before {
				content: "\f007";
				font-family: FontAwesome;
				margin-right: .3rem;

				@include breakpoint(medium) {
					color: $secondary;
				}
			}
		}
	}
}

	.sub {
		display: block;
		
		@include breakpoint(medium) {
			display: none;
			position: absolute;
			background: $light;
			padding: 1rem;
		}

		li {
			display: block;
			
			@include breakpoint(medium) {
				line-height: 2em;
			}
		}

		a {

		}
	}
}


.navi-add {
	font-size: .9rem;


	@include breakpoint(small) {
		display: flex;
		justify-content: space-between;
	}

	@include breakpoint(giant) {
		max-width: 70%;
		margin-left: 15%;
	}

	li {
		line-height: 2em;
		&:not(:last-child){
			border-bottom: 1px dotted $primary;
			@include breakpoint(small){
				border-bottom: none;
			}
		}
		a {
			color: $primary;
			transition: 300ms all;
			display: block;
			text-align: center;
			padding: 20px;
			&:hover,&.active {
				color:$alert;
			}
			@include breakpoint(medium){
				display: inline;
				padding: 0;
			}
		}
	}
}

// Mobile Menu Bar
////////////////////////////// 

$menu-bar: 50px;

.page-navi {

	// Off Canvas Navigation
	//////////////////////////////

	background:$secondary;
	left:-100%;
	overflow-y: auto;
	position: fixed;
	top:rem($menu-bar);
	transition:.6s;
	width:map-get($breakpoints, tiny) * .8;
	z-index: 895;
    bottom: 0;
    max-width:100%;

	// Desktop Navigation
	//////////////////////////////

	@include breakpoint(medium) {
		bottom:auto;
		left:auto;
		position: relative;
		top:auto;
		width: auto;
		background: $light;
		border-top: 1px solid rgba($border,.3);
		overflow: visible;
		overflow-y: initial;

		&.is_stuck {
			position: fixed;
			display: block;
			width: 100%;
			top: 0;
			left: 0;
		}
	}

	>.row {
		display: block;

		@include breakpoint(medium) {
			display: flex;
		}
	}
}

.mobile-bar {
	align-items:center;
	background:$dark;
	display: flex;
	height:rem($menu-bar);
	justify-content:stretch;
	left:0;
	position: fixed;
	right:0;
	top:0;
	z-index: 894;
	color: $light;

	li {
		display:flex;
		flex-grow:1;
		padding:0 rem($base-gap);

		&:last-child {
            justify-content:flex-end;
        }
	}

	@include breakpoint(medium) {
		display: none;
	}
}

label[for="navi-toggled"] {
	@extend .fa-bars;
	flex-direction:row-reverse;
	font-size: rem(18px);

	&:before {
		@extend .fa-default;
		margin-right: rem(10px);
	}

	&:after {
		background:rgba($dark,0.8);
		bottom:0;
		content:'';
		left:0;
		opacity: 0;
		pointer-events:none;
		position: fixed;
		top:rem($menu-bar);
		transition:opacity .6s;
		width: 100%;
		z-index:-1;
	}
}

#navi-toggled {
	display: none;

	&:checked {

		~ * label[for="navi-toggled"] {
			@extend .fa-close;

			&:after {
				opacity: 1;
				pointer-events:auto;
			}
		}

		~ * .page-navi,
		~ .page-navi {
			left:0;
		}
	}
}